import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Views from "./views";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from "./configs/AppConfig";
import "./translations/i18n";
import { useFirestoreConnect } from "react-redux-firebase";
import moment from "moment";
import "moment/locale/nl";
import "moment/locale/en-gb";
import i18next from "i18next";
import {
  FIRESTORE_CUSTOMER_REQUESTS_TABLE,
  FIRESTORE_CUSTOMERS_TABLE,
  FIRESTORE_INVITED_USERS_TABLE,
  FIRESTORE_USERS_TABLE,
} from "./constants/FirestoreConstant";
import { COMPANY_COUNTRY_CODE } from "./constants/CompanyConstants";
import { getFirebaseIDToken } from "./functions/firebase/firebase";
import { saveExactCustomers, saveExactProducts } from "./redux/actions/Exact";
import { useDispatch, useSelector } from "react-redux";
import { getAllExactCustomers, getAllExactProducts } from "./functions/api/exact";
import { changeLanguage } from "./translations/i18n";

moment.locale(i18next.language);

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const App = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.firebaseReducer.auth);
  const [exactProducts, setExactProducts] = React.useState(null);
  const [exactCustomers, setExactCustomers] = React.useState(null);
  const storageLanguage = localStorage.getItem("i18nextLng");
  // Fetch all collections from Firestore and put in redux store
  useFirestoreConnect([
    {
      collection: FIRESTORE_CUSTOMERS_TABLE,
      where: [
        ["customerRegion", "==", COMPANY_COUNTRY_CODE],
        ["status", "==", "active"],
      ],
    },
    {
      collection: FIRESTORE_CUSTOMERS_TABLE,
      where: [
        ["customerRegion", "==", COMPANY_COUNTRY_CODE],
        ["status", "==", "new"],
      ],
      storeAs: "newCustomers",
    },
    { collection: FIRESTORE_INVITED_USERS_TABLE },
    {
      collection: FIRESTORE_CUSTOMER_REQUESTS_TABLE,
      where: ["rejected", "!=", true],
    },
    {
      collection: FIRESTORE_USERS_TABLE,
      where: [["userRole", "in", ["admin", "superAdmin"]]],
      storeAs: "cmsUsers",
    },
  ]);

  useEffect(() => {
    if (!auth.isEmpty && (!exactProducts || !exactCustomers)) {
      getFirebaseIDToken().then((token) => {
        getAllExactProducts(token)
          .then((data) => {
            setExactProducts(data);
            dispatch(saveExactProducts(data));
          })
          .catch((err) => {
            console.log("err: ", err);
          });

        getAllExactCustomers(token)
          .then((data) => {
            setExactCustomers(data);
            dispatch(saveExactCustomers(data));
          })
          .catch((err) => {
            console.log("err: ", err);
          });
      });
    }
  }, [auth]);

  useEffect(() => {
    if (storageLanguage) {
      changeLanguage(storageLanguage);
    }
  }, [storageLanguage]);

  return (
    <div className="App">
      <ThemeSwitcherProvider
        themeMap={themes}
        defaultTheme={THEME_CONFIG.currentTheme}
        insertionPoint="styles-insertion-point"
      >
        <Router>
          <Switch>
            <Route path="/" component={Views} />
          </Switch>
        </Router>
      </ThemeSwitcherProvider>
    </div>
  );
};

export default App;
