export const COMPANY_CONSTANTS = {
  COMPANY_NAME: "Chef's Farm B.V.",
  STREET_AND_NUMBER: "Keienbergweg 26",
  ZIP_AND_CITY: "1101 GB, Amsterdam",
  COUNTRY: "Nederland",
  KVK: "",
  VAT: "",
  WEBSITE_URL: "shop.chefsfarm.nl",
  WEBSITE_NAME: "Chef's Farm",
};
export const LOGO_SRC = "/img/logo.png";
export const COMPANY_COUNTRY_CODE = "NL";
export const REGIONS = ["NL", "KW", "SG"];
