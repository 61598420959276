import React from "react";
import { Link } from "react-router-dom";
import { Grid, Menu } from "antd";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationConfig";
import { connect, useSelector } from "react-redux";
import { SIDE_NAV_LIGHT } from "constants/ThemeConstant";
import utils from "utils";
import { onMobileNavToggle } from "redux/actions/Theme";
import roles from "roles";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
  const { t } = useTranslation();
  const { sideNavTheme, routeInfo, hideGroupTitle, onMobileNavToggle } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };
  const role = useSelector((state) => state.firebaseReducer.profile.role);
  const newCustomers = useSelector((state) => state.firestoreReducer.ordered.newCustomers);
  const hasAccess = (menu) => {
    if (menu.isAdmin && role === roles.admin) return true;
    if (!menu.isAdmin) return true;
  };
  const hasNewCustomers = newCustomers && newCustomers.length > 0;

  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{ height: "100%", borderRight: 0, position: "relative" }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? "hide-group-title" : ""}
    >
      {navigationConfig.map(
        (menu) =>
          hasAccess(menu) &&
          (menu.submenu.length > 0 ? (
            <Menu.ItemGroup key={menu.key} title={t(menu.title)}>
              {menu.submenu.map((subMenuFirst) =>
                subMenuFirst.submenu.length > 0 ? (
                  <SubMenu
                    icon={subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                    key={subMenuFirst.key}
                    title={t(subMenuFirst.title)}
                  >
                    {subMenuFirst.submenu.map((subMenuSecond) => (
                      <Menu.Item key={subMenuSecond.key}>
                        {subMenuSecond.icon ? <Icon type={subMenuSecond?.icon} /> : null}
                        <span>
                          {t(subMenuSecond.title)}{" "}
                          {subMenuSecond.title === "customers_pending" && hasNewCustomers && (
                            <ExclamationCircleFilled style={{ fontSize: "12px", color: "#3e82f7" }} />
                          )}
                        </span>
                        <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item key={subMenuFirst.key}>
                    {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                    <span>{t(subMenuFirst.title)}</span>
                    <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
                  </Menu.Item>
                )
              )}
            </Menu.ItemGroup>
          ) : (
            <Menu.Item key={menu.key}>
              {menu.icon ? <Icon type={menu?.icon} /> : null}
              <span>{t(menu?.title)}</span>
              {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path} /> : null}
            </Menu.Item>
          ))
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return <SideNavContent {...props} />;
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
