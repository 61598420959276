import { SAVE_EXACT_CUSTOMERS, SAVE_EXACT_PRODUCTS } from "../constants/Exact";

const initState = null;

const exact = (state = initState, action) => {
  switch (action.type) {
    case SAVE_EXACT_PRODUCTS:
      const newProducts = action.payload;
      return {
        ...state,
        products: newProducts,
      };
    case SAVE_EXACT_CUSTOMERS:
      const newCustomers = action.payload;
      return {
        ...state,
        customers: newCustomers,
      };
    default:
      return state;
  }
};

export default exact;
