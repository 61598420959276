import React from "react";
import AppBreadcrumb from "components/layout-components/AppBreadcrumb";
import { useTranslation } from "react-i18next";

export const PageHeader = ({ title, display }) => {
  const { t } = useTranslation();
  return display ? (
    <div className="app-page-header">
      <h3 className="mb-0 mr-3 font-weight-semibold">{title ? t(title) : "Home"}</h3>
      <AppBreadcrumb t={t} />
    </div>
  ) : null;
};

export default PageHeader;
