import { SAVE_EXACT_CUSTOMERS, SAVE_EXACT_PRODUCTS } from "../constants/Exact";

export function saveExactProducts(products) {
  return (dispatch) => {
    dispatch({
      type: SAVE_EXACT_PRODUCTS,
      payload: products,
    });
  };
}

export function saveExactCustomers(customers) {
  return (dispatch) => {
    dispatch({
      type: SAVE_EXACT_CUSTOMERS,
      payload: customers,
    });
  };
}
