import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducers from "../reducers";
import firestoreConfig from "configs/FirestoreConfig";
import firebase from "configs/FirebaseConfig";
import { createFirestoreInstance } from "redux-firestore";
import { save, load } from "redux-localstorage-simple";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";

const store = createStore(
  reducers,
  load(),
  composeWithDevTools(applyMiddleware(thunk, save({ ignoreStates: ["queries", "exact"] }))),
);

export const rrfProps = {
  firebase,
  config: firestoreConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

export default store;
