const base = process.env.REACT_APP_BASEURL;
const api = process.env.REACT_APP_API_URL;
const dashboardUrl = "/cms";
const authUrl = "/auth";

module.exports = {
  base: base,
  dashboard: dashboardUrl,
  auth: authUrl,
  login: authUrl + "/login",
  forgotPassword: authUrl + "/forgot-password",
  acceptInvite: authUrl + "/invite/",
  products: dashboardUrl + "/products",
  productList: dashboardUrl + "/products/product-list",
  addProduct: dashboardUrl + "/products/add-product",
  editProduct: dashboardUrl + "/products/edit-product/",
  promotions: dashboardUrl + "/products/promotions",
  ordersList: dashboardUrl + "/orders",
  adminList: dashboardUrl + "/customers/admins",
  userList: dashboardUrl + "/customers/users",
  groups: dashboardUrl + "/groups",
  discountCodes: dashboardUrl + "/discounts",
  customerList: dashboardUrl + "/companies/all",
  snelstartCustomerList: dashboardUrl + "/companies/snelstart",
  exactCustomerList: dashboardUrl + "/companies/exact",
  snelstartOrdersList: dashboardUrl + "/orders/snelstart",
  customerListPending: dashboardUrl + "/companies/pending",
  users: dashboardUrl + "/admin/users",
  notifications: dashboardUrl + "/notifications",
  reports: dashboardUrl + "/reports",
  createSnelstartRelatie: api + "/api/snelstart/relatie",
  createExactAccount: api + "/api/exact/account",
  getAllSnelstartRelaties: api + "/api/snelstart/relaties",
  getAllExactAccounts: api + "/api/exact/accounts",
  getSnelstartOrders48h: api + "/api/snelstart/orders/48h",
  getSnelstartOrdersDateRange: api + "/api/snelstart/orders/date-range",
  getAllSnelstartProducts: api + "/api/snelstart/products",
  getAllExactProducts: api + "/api/exact/products",
  sendCustomerRequestRejectedMail: api + "/api/mail/customer_request/rejected",
  snelstartOrder: api + "/api/snelstart/verkooporder",
  exactOrder: api + "/api/exact/order",
  sendInvite: api + "/api/mail/invite/cms",
  inviteNewAppUser: api + "/api/mail/invite/app",
  changeUserPassword: api + "/api/firestore/password",
  activeCampaign: api + "/api/activecampaign",
};
