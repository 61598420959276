import firebase from "firebase";
import axios from "axios";
import urls from "../../urls";

export const getFirebaseIDToken = () => {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .currentUser?.getIdToken(true)
      .then((idToken) => resolve(idToken))
      .catch((error) => reject(error));
  });
};

export const changeUserPassword = (object, token) => {
  return new Promise((resolve, reject) => {
    const config = {
      headers: { Authorization: token },
    };

    axios
      .post(urls.changeUserPassword, object, config)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => reject(err));
  });
};
