import axios from "axios";
import urls from "../../urls";

export const createExactAccount = (values, token) => {
  return new Promise((resolve, reject) => {
    const extraVelden = [];
    // if (values.directDebit && values.accountHolder)
    //   extraVelden.push({ naam: "Rekeninghouder", waarde: values.accountHolder });
    // if (values.directDebit && values.accountHolderLocation)
    //   extraVelden.push({ naam: "Locatie rekeninghouder", waarde: values.accountHolderLocation });
    const addressLine = !!values.customerAddress ? values.customerAddress.split(",")[0] : null;
    const object = {
      id: values.customerId,
      AccountName: values.customerName,
      ContactFirstName: values.contact.userFirstName,
      ContactLastName: values.contact.userLastName,
      DeliveryAddressline1: addressLine,
      DeliveryPostCode: values.zipCode,
      DeliveryCity: values.city,
      DeliveryCountry: "nl",
      IBAN: values.cardNumber || null,
      VATNumber: values.vatNumber || null,
      ChamberofCommerceNumber: values.kvkNummer,
      PhoneNumber: values.customerPhone,
      EmailAddress: values.customerEmail,
      TextFreeField2: values.customerDeliveryDays,
      // incassoSoort: values.paymentMethod === "directDebit" ? "Core" : "Geen", // TODO
      // extraVeldenKlant: extraVelden,
    };

    const config = {
      headers: { Authorization: token },
    };

    axios
      .post(urls.createExactAccount, object, config)
      .then((result) => {
        resolve(result);
      })
      .catch((err) => reject(err));
  });
};

export const getAllExactCustomers = (token) => {
  return new Promise((resolve, reject) => {
    const config = {
      headers: { Authorization: token },
    };
    axios
      .get(urls.getAllExactAccounts, config)
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => reject(err));
  });
};

// export const getExactOrders48h = (token) => {
//   return new Promise((resolve, reject) => {
//     const config = {
//       headers: { Authorization: token },
//     };
//     axios
//       .get(urls.getExactOrders48h, config)
//       .then((result) => {
//         resolve(result.data);
//       })
//       .catch((err) => reject(err));
//   });
// };

// export const getSnelstartOrdersDateRange = (token, dateRange) => {
//   return new Promise((resolve, reject) => {
//     const config = {
//       headers: { Authorization: token },
//     };
//     axios
//       .post(urls.getSnelstartOrdersDateRange, dateRange, config)
//       .then((result) => {
//         resolve(result.data);
//       })
//       .catch((err) => reject(err));
//   });
// };

export const sendOrderToExact = (order, token) => {
  return new Promise(function (resolve, reject) {
    const config = {
      headers: { Authorization: token },
    };

    axios
      .post(urls.exactOrder, order, config)
      .then((response) => {
        if (response.status === 201) resolve();
        else reject();
      })
      .catch((error) => {
        console.error("Error response:", error.response);
        reject(error.response?.data?.message || error.message);
      });
  });
};

export const getAllExactProducts = (token) => {
  return new Promise((resolve, reject) => {
    const config = {
      headers: { Authorization: token },
    };
    axios
      .get(urls.getAllExactProducts, config)
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => reject(err));
  });
};
